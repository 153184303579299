<template>
  <div>
    <spinner :loading="processing" />
    <center-content-on-screen>
      <div class="password_wrapper">
        <content-box title="Minha senha" icon="fa-key">
          <div class="form-group">
            <label>Senha atual:</label>
            <password-input v-model="form.current_password" />
            <small>{{ form_errors.current_password }}</small>
          </div>
          <div class="form-group">
            <label>Nova senha:</label>
            <password-input v-model="form.new_password" />
            <small>{{ form_errors.new_password }}</small>
          </div>
          <div class="form-group">
            <label>Confirmação:</label>
            <password-input v-model="form.new_password_confirmation" />
            <small>{{ form_errors.new_password_confirmation }}</small>
          </div>
          <process-cancel-buttons @cancel="cancel" @process="process_info" />
        </content-box>
      </div>
    </center-content-on-screen>
  </div>
</template>
<script type="text/javascript">
import { mapActions } from 'vuex';
import ContentBox from '@/theme/boxes/ContentBox.vue';
import CenterContentOnScreen from '@/theme/boxes/CenterContentOnScreen.vue';
import PasswordInput from '@/features/passwordInput/PasswordInput.vue';
import ProcessCancelButtons from '@/theme/buttons/ProcessCancelButtons.vue';
import ShowHttpErrors from '@/mixins/ShowHttpErrors/showhttperrors';
import Spinner from '@/features/Spinner/Spinner.vue';
import functions from '@/functions';

export default {
  components: {
    ContentBox,
    CenterContentOnScreen,
    PasswordInput,
    ProcessCancelButtons,
    Spinner,
  },
  mixins: [ShowHttpErrors],
  data() {
    return {
      form: {
        current_password: '',
        new_password: '',
        new_password_confirmation: '',
      },
      form_errors: {
        current_password: '',
        new_password: '',
        new_password_confirmation: '',
      },
      processing: false,
    };
  },
  methods: {
    ...mapActions('password_change', ['ActionChangeCurrentUserPassword']),
    cancel() {
      this.$router.back();
    },
    async process_info() {
      try {
        this.processing = true;
        this.reset_error_form();
        await this.ActionChangeCurrentUserPassword(this.form);
        this.reset_form();
        await this.$dialogbox.success('Senha alterada');
        this.cancel();
      } catch (error) {
        this.form_errors = this.putHttpErrorMessagesOnTheForm(error);
      } finally {
        this.processing = false;
      }
    },
    reset_error_form() {
      functions.cleanObjectProperties(this.form_errors);
    },
    reset_form() {
      functions.cleanObjectProperties(this.form);
    },
  },
};
</script>
<style type="text/css" scoped>
.password_wrapper {
  width: 100%;
  max-width: 500px;
}
</style>
